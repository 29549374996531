import * as moment from "moment";

export function formatPeriod(period) {
  let periods = [
    { name: "Hoje", id: 0 },
    { name: "Ontem", id: 1 },
    { name: "Esta semana", id: 2 },
    { name: "Este mês", id: 3 },
    { name: "Período personalizado", id: 4 },
  ];
  let showCalendar;
  let fromDate = "";
  let toDate = "";

  switch (period) {
    case periods[0].name: // 'Hoje'
      showCalendar = false;
      fromDate = moment().format("YYYY-MM-DD");
      toDate = moment().format("YYYY-MM-DD");

      break;

    case periods[1].name: // 'Ontem'
      showCalendar = false;
      fromDate = moment().subtract(1, "days").format("YYYY-MM-DD");
      toDate = moment().subtract(1, "days").format("YYYY-MM-DD");

      break;

    case periods[2].name: // 'Esta semana'
      showCalendar = false;
      fromDate = moment().startOf("week").format("YYYY-MM-DD");
      toDate = moment().format("YYYY-MM-DD");

      break;

    case periods[3].name: // 'Este mês'
      showCalendar = false;
      fromDate = moment().startOf("month").format("YYYY-MM-DD");
      toDate = moment().format("YYYY-MM-DD");

      break;

    case periods[4].name: // personalizado
      showCalendar = true;
      break;
  }
  return [fromDate, toDate, showCalendar];
}
