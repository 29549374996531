<template>
  <v-container v-if="hasUser" fluid>
    <v-row class="py-0 my-0">
      <!-- USER CARD -->
      <v-col class="col-12 col-md-3 py-0 my-0">
        <v-card outlined>
          <v-progress-linear
            v-if="showLoadingUserCard"
            indeterminate
            color="primaryTrack"
          ></v-progress-linear>

          <v-card-title>
            <v-avatar>
              <img
                v-if="user.photo == '' || user.photo == null"
                src="@/assets/user-default.png"
              />
              <img v-else :src="user.photo" />
            </v-avatar>
            <div class="name-text ml-2">{{ user.name }}</div>
          </v-card-title>

          <v-card-text v-if="showLoadingUserCard">Carregando...</v-card-text>
          <div v-else>
            <v-card-text>
              <div class="pb-1 black--text">{{ user.email }}</div>
              <div v-if="status != ''">
                <span
                  v-if="status == 'false'"
                  class="red--text font-weight-bold"
                  >Offline</span
                >
                <span v-else class="green--text font-weight-bold">Online</span>
              </div>

              <div class="mt-2 mb-2" v-if="lastDate != ''">
                <div>
                  <strong>Última atividade:</strong>
                </div>
                <label>{{ formatDateAndHour(lastDate, true) }}</label>
              </div>

              <div class="mt-2 mb-2" v-if="firstDate">
                <div>
                  <strong>Primeira atividade:</strong>
                </div>
                <label>{{ formatDateAndHour(firstDate, true) }}</label>
              </div>

              <div>
                <div>
                  <strong>Total de horas no período:</strong>
                </div>
                <label>{{ secondsToTime(totalTimeInSecondsByPeriod) }}</label>
              </div>
            </v-card-text>

            <!-- COMPONENTE DE SELECIONAR PERIODO -->
            <v-card-text>
              <v-divider></v-divider>
              <div class="mx-3">
                <br />
                <v-row @click="id = []">
                  <v-autocomplete
                    :items="periods"
                    :filter="customFilter"
                    v-model="period"
                    item-text="name"
                    label="Período personalizado"
                    single-line
                    color="accentTrack"
                    prepend-icon="mdi-calendar"
                    hint="Selecione um perído para visualizar"
                    persistent-hint
                  ></v-autocomplete>
                </v-row>
                <v-row v-if="showCalendar">
                  <CalendarTrack
                    :maximumDate="today"
                    @selectedDate="customPeriod = $event"
                  />
                  <label v-if="fromDate || toDate"
                    >Informações do filtro:</label
                  >
                  <ul>
                    <li v-if="fromDate">Início: {{ formatDate(fromDate) }}</li>
                    <li v-if="toDate">Final: {{ formatDate(toDate) }}</li>
                  </ul>
                </v-row>

                <v-row>
                  <v-btn
                    outlined
                    small
                    color="#ff0000"
                    dark
                    v-if="showButtonAplyFilterByPeriodDate"
                    @click="aplyFilterByPeriodDate()"
                    >Aplicar</v-btn
                  >
                </v-row>
              </div>
            </v-card-text>
          </div>
        </v-card>
      </v-col>

      <!-- CONTENT CARDS -->
      <v-col class="col-12 col-md-9 py-0 my-0">
        <v-card max-width="1122" outlined>
          <v-progress-linear
            v-if="showLoading"
            indeterminate
            color="primaryTrack"
          ></v-progress-linear>
          <v-row no-gutters>
            <v-col class="mx-2">
              <v-card-title>Performance do usuário</v-card-title>
            </v-col>
            <v-col align="end">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="my-4 mx-5"
                    color="accentTrack"
                    dark
                    @click="update()"
                  >
                    Atualizar
                    <v-icon dark right>mdi-cached</v-icon>
                  </v-btn>
                </template>
                <span>
                  Aqui você atauliza os dados do
                  <strong>Conecta Track</strong>
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
          <div v-if="error == true" align="center" justify="center">
            <v-icon size="65">mdi-laptop-off</v-icon>
            <v-card-text>Nenhuma informação encontrada</v-card-text>
          </div>
          <div v-else class="chart-container">
            <v-chart :options="chartOptionsPie" autoresize />
          </div>
        </v-card>

        <v-card v-if="performanceDataUser != ''" class="mt-4" max-width="1122">
          <v-progress-linear
            v-if="showLoading"
            indeterminate
            color="secondary"
          ></v-progress-linear>
          <v-data-table
            class="elevation-1"
            :headers="headers"
            disable-pagination
            :items="performanceDataUser"
            hide-default-footer
          >
            <template v-slot:[`item.color`]="{ item }">
              <v-row>
                <div class="pa-2">
                  <v-avatar size="30" :color="item.color"></v-avatar>
                </div>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else class="d-flex justify-center mb-6 my-6">
    <v-col cols="12">
      <div class="text-center">
        <h3>Ops... O ID não foi encontrado.</h3>
      </div>
    </v-col>
  </v-container>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/chart/pie";
import "echarts/lib/component/polar";
import * as moment from "moment";
import { formatPeriod } from "@/helpers/services/track";
import CalendarTrack from "@/components/main/shared/CalendarTrack";
import { formatDateAndHour, formatDate } from "@/helpers/services/utils";
import { findUserById } from "@/helpers/services/getUsers";

export default {
  components: {
    "v-chart": ECharts,
    CalendarTrack,
  },

  data() {
    return {
      hidden: false,
      gif_track: require("@/assets/images/gif_track.gif"),
      showLoading: false,
      showLoadingUserCard: false,
      showButtonAplyFilterByPeriodDate: false,
      error: false,
      user: false,
      totalTimeInSecondsByPeriod: 0,
      period: "",
      customPeriod: [],
      today: "",
      showCalendar: false,
      selectedDate: [],
      periods: [
        { name: "Hoje", id: 0 },
        { name: "Ontem", id: 1 },
        { name: "Esta semana", id: 2 },
        { name: "Este mês", id: 3 },
        { name: "Período personalizado", id: 4 },
      ],
      fromDate: "",
      toDate: "",
      timePickedStart: "8:00",
      timePickedEnd: "18:00",
      menuTimePicker: false,
      performanceDataUser: [],
      colorPalette: [
        "#c23531",
        "#2f4554",
        "#61a0a8",
        "#d48265",
        "#91c7ae",
        "#749f83",
        "#ca8622",
        "#bda29a",
        "#6e7074",
        "#546570",
      ],
      chartOptionsPie: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        series: [
          {
            name: "Tempo em cada aba do navegador (minutos)",
            type: "pie",
            color: [],
            radius: ["30%", "50%"],
            avoidLabelOverlap: true,
            label: {
              show: true,
              position: "outside",
              fontSize: 14,
            },
            data: [],
          },
        ],
      },
      headers: [
        { text: "Cor", value: "color", align: "start", sortable: false },
        { text: "Título", value: "name" },
        { text: "Tempo", value: "time_in_hour" },
      ],
      status: "",
      lastDate: "",
      firstDate: null,
    };
  },

  beforeMount() {
    this.loadUserInfo();
  },

  mounted() {
    this.period = "Hoje";
  },

  watch: {
    "$route.params.id": {
      handler: function (value) {
        this.loadUserInfo(value);
        this.update();
      },
      deep: true,
      immediate: true,
    },
    period() {
      [this.fromDate, this.toDate, this.showCalendar] = this.formatPeriod(
        this.period
      );
    },

    customPeriod(newValue) {
      let auxFromDate = "";
      let auxToDate = "";

      [auxFromDate, auxToDate] = newValue;

      console.log(this.selectedDate);
      console.log(newValue);

      if (auxFromDate != "" && auxToDate != "" && auxFromDate != auxToDate) {
        this.fromDate = auxFromDate;
        this.toDate = auxToDate;
        this.showButtonAplyFilterByPeriodDate = false;
        this.update();
      }

      if (auxFromDate == auxToDate) {
        this.showButtonAplyFilterByPeriodDate = true;
      }
    },

    fromDate(newValue) {
      if (newValue != "") {
        this.update();
      }
    },
  },

  computed: {
    timeIntervalFormatted() {
      let time1 = this.timePickedStart;
      let time2 = this.timePickedEnd;

      return `${time1} - ${time2}`;
    },

    hasUser() {
      return this.user;
    },
  },

  methods: {
    formatDateAndHour,
    formatDate,
    formatPeriod,
    findUserById,

    update() {
      if (this.user) {
        this.getInfoTrack();
        this.getUserStatus();
      }
    },

    loadUserInfo() {
      this.scrollToTop();
      this.today = moment().format("YYYY-MM-DD");
      this.toDate = this.today;
      this.fromDate = this.today;
      this.user_id = this.$route.query.id;
      this.user = this.findUserById(this.user_id);
    },

    customFilter(item, queryText) {
      const textOne = item.name.toLowerCase().trim();
      const searchText = queryText.toLowerCase().trim();
      return textOne.indexOf(searchText) > -1;
    },

    getInfoTrack() {
      let _this = this;
      this.totalTimeInSecondsByPeriod = 0;
      this.showLoading = true;
      _this.error = false;
      let params = {
        email: this.user.email,
        init_date: this.fromDate,
        end_date: this.toDate,
      };

      const token = localStorage.getItem("token");
      this.$axios
        .get(process.env.VUE_APP_API_BASE_URL + "/track", {
          params: { params },
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          _this.performanceDataUser = [];
          let sum = 0;

          if (response.data.data == "") {
            _this.performanceDataUser = "";
            _this.error = true;
          } else {
            response.data.data.map((c, i) => {
              if (i < 9) {
                _this.performanceDataUser.push({
                  color: this.colorPalette[i],
                  name: c.url,
                  value: (c.time_in_seconds / 60).toFixed(2),
                  time_in_hour: this.secondsToTime(
                    c.time_in_seconds.toFixed(2)
                  ),
                });
              } else {
                _this.performanceDataUser.push({
                  color: this.colorPalette[9],
                  name: c.url,
                  value: (c.time_in_seconds / 60).toFixed(2),
                  time_in_hour: this.secondsToTime(
                    c.time_in_seconds.toFixed(2)
                  ),
                });
                sum = sum + c.time_in_seconds;
              }

              _this.totalTimeInSecondsByPeriod += c.time_in_seconds;
            });
          }

          _this.chartOptionsPie.series[0].data =
            _this.performanceDataUser.slice(0, 9);
          if (_this.performanceDataUser.length > 9) {
            _this.chartOptionsPie.series[0].data.push({
              color: this.colorPalette[9],
              name: "Outros",
              value: (sum / 60).toFixed(2),
            });
          }
          _this.showLoading = false;
        })
        .catch((err) => {
          this.showSnackBarFailure = true;
          _this.response = err;
          _this.showLoading = false;
        });
    },

    getUserStatus() {
      this.showLoadingUserCard = true;
      let _this = this;
      let params = {
        email_user: this.user.email,
        init_date: this.fromDate,
        end_date: this.toDate,
      };

      const token = localStorage.getItem("token");
      this.$axios
        .get(process.env.VUE_APP_API_BASE_URL + "/track/online/single", {
          params: { params },
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          if (response.data.data[0].status) {
            _this.status = response.data.data[0].status;
            _this.lastDate = response.data.data[0].last_date;
            _this.firstDate = response.data.data[0].first_date;
            _this.showLoadingUserCard = false;
          }
        })
        .catch((err) => {
          console.log(err);
          _this.showLoadingUserCard = false;
        });
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    secondsToTime(seconds) {
      var hours = Math.floor(seconds / 3600);
      var minutes = Math.floor((seconds - hours * 3600) / 60);
      var sec = (seconds - hours * 3600 - minutes * 60).toFixed(0);
      var time = "";

      if (hours != 0) {
        time = hours + ":";
      } else {
        time = "00:";
      }

      if (minutes != 0) {
        minutes = minutes < 10 && time !== "" ? "0" + minutes : String(minutes);
        time += minutes + ":";
      } else {
        time += "00:";
      }

      time += sec < 10 ? "0" + sec : String(sec);

      return time;
    },

    aplyFilterByPeriodDate() {
      [this.fromDate, this.toDate] = this.customPeriod;
      this.showButtonAplyFilterByPeriodDate = false;
      this.update();
    },
  },
};
</script>

<style>
.echarts {
  width: 100%;
  height: 100%;
}

.chart-container {
  position: relative;
  height: 50vh;
  margin: auto;
}
.v-time-picker-title__time .v-picker__title__btn,
.v-time-picker-title__time span {
  font-size: 3.125rem;
}
</style>
